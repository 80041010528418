.login.page--front {display: flex;align-items: center;flex-direction: column;justify-content: space-between;width: 100%;height: 100vh;padding: 0;position: relative;-webkit-box-pack: justify;-ms-flex-pack: justify;-webkit-box-align: center;-webkit-box-orient: vertical;}
.login .page__cell {vertical-align: middle;display: flex;align-items: center;flex: 1;-webkit-box-flex: 1;-ms-flex: 1;-webkit-box-align: center;-ms-flex-align: center;}
.login.page--front .page__cell .container {z-index: 2;}
.login .container--narrow {max-width: 650px;}
.login .container--fluid {margin-right: auto;margin-left: auto;position: relative;padding: 0 15px;width: 100%;}
.login .container--narrow .box--white {box-shadow: 0 0 50px 25px rgba(0, 0, 0, 0.1);padding: 30px;width: 100%;display: inline-block;position: relative;background: #fff;border-radius: 5px;position: relative;}
.login .box--white .logo {margin: 0 auto 30px;display: table;}
.login .box--white .box__centered {max-width: 70%;margin: 40px auto 20px;}
.login .box__centered--form .field_cover input[type="text"],
.login .box__centered--form .field_cover input[type="password"] {padding-left: 45px;}
.login .box__centered--form input[type="text"],
.login .box__centered--form input[type="password"] {height: 45px;background-color: #f3f6fb;border: 2px solid #f3f6fb;}
.login input[type="text"],
.login input[type="email"],
.login input[type="phone"],
.login input[type="search"],
.login input[type="password"],
.login textarea,
.login select,
.login input[type="number"],
.login input[type="file"] {padding: 0 10px;width: 100%;color: #666;font-family: inherit;font-size: 1em;line-height: 110%;border-radius: 5px;}
.login .field_cover--lock:before {content: "\f13e";position: absolute;font-size: 20px;color: #c3c3c3;font-family: "FontAwesome";left: 15px;top: 8px;z-index: 1;}
.login .field_cover--user:before {position: absolute;content: "\f007";font-size: 20px;color: #c3c3c3;font-family: 'FontAwesome';left: 16px;top: 8px;z-index: 1;}
.login .field_cover {position: relative;}
.login .field-set {display: table;width: 100%;margin: 0 0 20px 0;table-layout: fixed;}
.login .box__centered--form input[type="submit"] {display: block;width: 100%;height: 45px;font-size: 1.2em;font-weight: 500;}
.login .web_form input[type="submit"],
.login .web_form input[type="button"] {border-radius: 3px;padding: 0 25px;text-transform: uppercase;position: relative;cursor: pointer;vertical-align: middle;border: none;color: #fff;margin-right: 10px;line-height: normal;background: linear-gradient(to bottom, rgba(45, 200, 239, 1) 0%, rgba(84, 209, 241, 1) 100%);-webkit-box-shadow: 0 4px 4px rgba(84, 209, 241, 0.5);box-shadow: 0 4px 4px rgba(84, 209, 241, 0.5);}
.login .box__centered--form input[type="text"]:focus,
.login .box__centered--form input[type="password"]:focus {background-color: #fff;box-shadow: none;border-color: #54d1f1;}
.login .errorlist {margin: 0;list-style: none;padding: 2px 10px 4px;position: relative;background: #fdf7f6;clear: both;}
.login .errorlist li span {color: #F00;font-size: 11px;text-decoration: none;}
.login input.error {border-color: #F00 !important;}
