.filterBtnCss{background-color: #fff;color: #000;box-shadow:none;    padding: 9px 25px 9px 10px;font-size: 13px;}
.filter_img_css{padding-right: 8px;}
.filterTab{display: flex;flex-wrap: nowrap;padding: 0;margin: 0;list-style-type: none;}
.filterTab_handle{display: inline-block;margin-right: 16px;position: relative;white-space: nowrap;font-size: 0.9rem;}
.filterTab_css{padding: 1px 16px;position: relative;font-size: 13px;}
.dropdownWrapper{margin-top: 12px;box-shadow: 0px 2px 3px 2px rgba(0, 0, 0, 0.25);border-radius: 5px;padding: 15px 20px;position: absolute;z-index: 99;background: #fff;display: none;min-height: 70px;max-height: 205px;overflow-y: scroll;}
.filter_name{font-size: 13px;align-items: center;display: flex;}
.filterTab_handle ul li{padding: 10px 0;}
input[type=checkbox] {display: none;}


input.search_btn{background: url('../../../assets/images/search-icon.svg');background-size: 22px;background-repeat: no-repeat;height: 22px;font-size: 0;margin: 0;padding: 0;position: absolute;left: 12px;top: 10px;width: 22px;box-shadow: none;}
input.KeywordInputCss {padding-left: 45px;font-size: 14px;}
.ClearBtn{display: inline-block;margin-left: 15px;}
.ClearBtn img{margin-bottom: 2px;}