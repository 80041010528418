.location .modal-header{justify-content: center;border-bottom: none;padding-top: 30px;}
.location .btn-close{position: absolute;right: 16px;top: 16px;}
.location .modal-title{font-size: 18px;}
.location .text-content{text-align: center;}
.location .modal-dialog {max-width: 516px;}
.location .modal-body{padding: 16px 40px;}
.location .btn-close{background: transparent url('../../../assets/images/crossIcon.svg') center/1em auto no-repeat;    color: #000;font-size: 11px;}
.location input[type="radio"]:checked::before {height: 12px;  width: 12px;background: #D31334;border-radius: 10px;border-right: none;border-bottom: none;right: 0;top: 0;bottom: 0;margin: auto;position: absolute;content: '';display: inline-block;font-size: 16px;text-align: center;line-height: 16px;left: 0px;outline: 1px solid #D31334;}
.location input[type="radio"] {appearance: none;background-color: #fff;color: #757575;width: 24px;height: 24px;border: 2px solid currentColor;border-radius: 50%;transform: translateY(8px);}
.location span {padding-left: 13px;color: #000;}
.location .loc_address {padding-left: 36px;color: #757575;}
.location .modal-footer{border: none;}