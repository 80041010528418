body {font-size: 14px;}
:focus-visible {
  outline: none;
}

.btn-check:focus+.btn, .btn:focus {
  outline: 0;
  box-shadow: none;
}

body{font-family: "Inter", sans-serif;color: #000000;}
img{max-width: 100%;}

.Toastify__toast-container .Toastify__toast-theme--colored.Toastify__toast--success {
  background: #00b6ad;
}

.Toastify__toast-container .Toastify__toast-theme--colored.Toastify__toast--error {
  background: #f35f5f;
}

.Toastify__toast-container.Toastify__toast-container--bottom-center{
  width: 400px;
}

.page-container {position: fixed;left: 0;right: 0;top: 0;bottom: 0;background-color: rgb(84, 209, 241);background: linear-gradient(135deg, rgba(84, 209, 241, 1) 0%, rgba(246, 118, 192, 1) 100%);background-repeat: no-repeat;background-size: 300% 300%;animation: AnimationName 8s infinite linear;-webkit-animation: AnimationName 8s infinite linear;}

a {
  color: inherit;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  text-decoration: none;

}


h1, h2, h3, h4, h5, h6 {
  font-size: 100%;
  font-weight: 500;
  font-style: normal;
  text-transform: none;
  padding: 0 0 10px 0;
  color: inherit;
  font-family: inherit;
  line-height: 1.2;
  margin: 0;
  padding: 0;
  border: 0;
}

.pagination li a svg{width: 7px;margin-bottom: 3px;}
.pagination li a {    cursor: pointer;}
@keyframes AnimationName {
  0% {
    background-position: 0% 60%;
  }
  50% {
    background-position: 100% 60%;
  }
  100% {
    background-position: 0% 60%;
  }
}

@-webkit-keyframes AnimationName {
  0% {
    background-position: 0% 60%;
  }
  50% {
    background-position: 100% 60%;
  }
  100% {
    background-position: 0% 60%;
  }
}



/* .dropdownWrapper_js::-webkit-scrollbar
{
  display: none;
} */


.dropdownWrapper_js::-webkit-scrollbar-track
{
	/* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); */
	background-color: #F5F5F5;
}

.dropdownWrapper_js::-webkit-scrollbar
{
	width: 6px;
	background-color: #F5F5F5;
}

.dropdownWrapper_js::-webkit-scrollbar-thumb
{
	background-color: #c4c4c4;
}





input[type=checkbox] + label:active:before {
  transform: scale(0);
}

input[type=checkbox]:checked + label:before {
  background-image: url('./assets/images/check.svg');
  background-size: 18px;
  background-repeat: no-repeat;
  background-position-x: 50%;
  background-position-y: center;
}

input[type=checkbox] + label:before {content: "";border: 2px solid #757575;border-radius: 0.2em;display: inline-block;width: 24px;height: 24px;margin-right: 14px;vertical-align: bottom;color: transparent;transition: .2s;}

input.search_btn{background: url('./assets/images/search-icon.svg');background-size: 22px;background-repeat: no-repeat;height: 22px;font-size: 0;margin: 0;padding: 0;position: absolute;left: 12px;top: 10px;width: 22px;box-shadow: none;}
input.KeywordInputCss {padding-left: 45px;}
